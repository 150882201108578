import { Show } from 'solid-js'

import { Button } from '#/components/ui/Button'

import { useAuth } from './AuthProvider'

const LogoutButton = () => {
  const auth = useAuth()
  return (
    <Show when={auth.isLoggedIn}>
      <Button onClick={auth.logout}>Logout</Button>
    </Show>
  )
}

export { LogoutButton }
